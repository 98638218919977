<script setup lang="ts">
import { extend, ValidationObserver } from 'vee-validate'
import { ref, nextTick, computed, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useForm } from '/~/composables/base/use-form'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'
import { PointsPartnerType } from '/~/templates/bill-payments/composables/points-programs/use-points-programs'

const props = withDefaults(
  defineProps<{
    calculating?: boolean
    disabled?: boolean
  }>(),
  {
    calculating: false,
    disabled: false,
  }
)

const emit = defineEmits<{
  (event: 'valid', value: boolean): void
  (event: 'update:calculating', value: boolean): void
  (
    event: 'converted',
    value: {
      valueFrom: string
      valueTo: string
      amount: string
    }
  ): void
  (event: 'click'): void
}>()

const route = useRoute()
const { pointsBalance } = usePoints()
const { providerTitle } = useProvider()
const { calculatePoints, selectedPartner, selectPartner } = usePointsPrograms()
const { validationObserverRef } = useForm()

const valueToDisabled = ref(false)
const valueTo = ref<string>('0')
const valueFrom = ref<string>('0')
const timerId = ref<NodeJS.Timeout>()
const partnerType = computed(() => route.query.type)

type Partner = {
  type: string
}

const partner = computed<Partner>(() => selectedPartner.value as Partner)

const labelFrom = computed(() => `${providerTitle.value} Points`)
const prefixFrom = 'PTS'
const prefixTo = computed(() => {
  return selectedPartner.value.programCurrency
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('')
})

const valid = computed(() => validationObserverRef.value?.flags.valid)

const disabledFrom = computed(() => {
  return props.disabled
})

watch(valid, (value) => {
  emit('valid', Boolean(value))
})

function calculateFrom(amount: string) {
  emit('update:calculating', true)
  valueToDisabled.value = true

  calculatePoints({
    type: partner.value?.type,
    points: amount,
  })
    .then((r) => {
      valueTo.value = String(r.data.programPoints)
      emit('valid', true)

      nextTick(() => {
        emit('converted', {
          valueFrom: valueFrom.value,
          valueTo: valueTo.value,
          amount: r.data.amount,
        })
      })
    })
    .catch((error) => {
      emit('valid', false)
      valueTo.value = ''
      validationObserverRef.value?.setErrors({
        valueFrom: [error.data?.message],
      })
    })
    .finally(() => {
      emit('update:calculating', false)
      valueToDisabled.value = false
    })
}

function throttledCalculate(
  calculate: (amount: string) => void,
  amount: string
) {
  valueFrom.value = parseInt(amount).toString()
  if (timerId.value) {
    clearTimeout(timerId.value)
  }
  timerId.value = setTimeout(async () => {
    const isValid = await validationObserverRef.value?.validate()

    if (!amount) {
      valueFrom.value = ''
      valueTo.value = ''
      emit('converted', {
        valueFrom: '',
        valueTo: '',
        amount: '',
      })
    } else if (isValid) {
      calculate(amount)
    } else {
      valueTo.value = ''
    }
  }, 800)
}

if (!partner.value.type) {
  selectPartner(partnerType.value)
}

extend('points_max_value', {
  validate: (value, params) => {
    const DEFAULT_MESSAGE =
      "You don't have enough points to transfer the entered amount"
    const { max } = params as { max: string }
    const maxValue = parseFloat(max)

    if (maxValue === 0) return DEFAULT_MESSAGE

    const parsedValue = parseFloat(String(value).replace(' ', ''))

    if (parsedValue === 0 || isNaN(parsedValue))
      return 'Enter a valid amount of points to transfer'

    return parsedValue <= maxValue ? true : DEFAULT_MESSAGE
  },
  params: ['max'],
})
</script>

<template>
  <validation-observer
    v-slot="{ errors, failed }"
    ref="validationObserverRef"
    slim
  >
    <div class="flex flex-col items-center" @click="$emit('click')">
      <div class="flex">
        <div class="w-3/4 overflow-hidden">
          <div
            class="mb-1 truncate text-xs text-eonx-neutral-800 md:text-base"
            :class="{
              'text-red-700': failed,
              'text-eonx-neutral-600': !failed,
            }"
          >
            {{ labelFrom }}
          </div>
          <base-input
            v-model="valueFrom"
            :validation="{
              rules: `points_max_value:${pointsBalance}`,
              vid: 'valueFrom',
            }"
            type="number"
            name="valueFrom"
            class="!m-0 !p-0"
            debounce="500"
            :label="labelFrom"
            nolabel
            placeholder=" "
            :disabled="disabledFrom"
            @input="throttledCalculate(calculateFrom, $event)"
          >
            <!-- TODO: nolabel + empty placeholder - check why we are hiding label only for .base-field__label--expanded -->
            <template #error>
              <portal to="points-converter-errors">
                <div v-if="errors['valueFrom']" class="text-sm text-fg-error">
                  {{ errors['valueFrom'][0] }}
                </div>
              </portal>
            </template>
            <template #prefix>
              <div
                class="font-bold"
                :class="{
                  'text-red-700': failed,
                  'text-eonx-neutral-600': !failed,
                }"
              >
                {{ prefixFrom }}
              </div>
            </template>
          </base-input>
        </div>
        <div
          class="mt-5 flex h-10 w-14 flex-shrink-0 items-center justify-center text-black md:mt-7 md:w-20"
        >
          <base-icon
            v-if="!calculating"
            svg="heroicons/solid/arrow-long-right"
            class="h-7 w-7"
          />

          <base-loader v-else size="sm" />
        </div>

        <div class="w-3/4 overflow-hidden">
          <div class="mb-1 truncate text-xs text-eonx-neutral-800 md:text-base">
            {{ partner.programCurrency }}
          </div>
          <base-input
            v-model="valueTo"
            type="number"
            class="!m-0 !p-0"
            :disabled="true"
          >
            <template #prefix>
              <span class="font-bold text-eonx-neutral-600">
                {{ prefixTo }}
              </span>
            </template>
          </base-input>
        </div>
      </div>
      <portal-target name="points-converter-errors" class="w-full" />
    </div>
  </validation-observer>
</template>
